.App {
  text-align: center;
}

.content-area{
  min-height: 280px;
  color: black !important;
}

.video{
  justify-content: center;
}

.divider{
  color: white !important;
}

.extra-content{
  min-height: 125px;
}

.bio{
  text-align: center;
}

.welcome {
  color:white;
}

.staticLink{
  color:white !important;
  text-decoration: underline !important;
}

.textColorWhite{
  color: white !important;
}

.image-container{
  margin-left: 25%
} 

.homebackground{
  background-image: url(../src/assets/fashion/Abyon3.jpg);
}

.corpPics{
  margin: 5% 10% 5% 10%;
  width: 80%;
  align-items: center;
}

.Header{
  font-size: 36pt;
  /* background-color: transparent !important; */
}

.background{
  height: 100%;
  background-color: black;
}